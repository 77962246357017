import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取cedar配置
export function getcedarConfig(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/CedarConfig`,
        method: 'get'
    })
}
// 更新cedar配置
export function modifycedarConfig(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/CedarConfig`,
        method: 'put',
        data: params,
    })
}
// 获取cedar报告
export function getcedarReportList(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/cedarReports?sort=id,desc`,
        method: 'get'
    })
}
// 上传cedar报告
export function updateCedarReportList(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/cedarReports`,
        method: 'post',
        data: params.data
    })
}