import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取关联列表
export function getMassMessageChannels(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params}/massMessageChannels`,
    method: 'get'
  })
}
// 新增关联列表
export function addMassMessageChannels(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/massMessageChannels`,
    method: 'post',
    data: params.data
  })
}
// 修改关联列表
export function modifyMassMessageChannels(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/massMessageChannels/${params.channelId}`,
    method: 'put',
    data: params.data
  })
}
// 删除关联列表
export function deleteMassMessageChannels(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/massMessageChannels/${params.channelId}`,
    method: 'delete'
  })
}