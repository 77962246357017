import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 保存项目的eolink配置信息
export function eolinkConfig(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/eolinkConfig`,
    method: "post",
    data: params.data
  });
}
// 获取指定测试用例节点关联的所有文档
export function getapiDocs(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testcase/${params.nodeKey}/apiDocs`,
    method: "get"
  });
}
// 添加Api文档
export function addapiDocs(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testcase/${params.nodeKey}/apiDocs`,
    data: params.data,
    method: "post"
  });
}
// 删除Api文档
export function deleteapiDocs(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testcase/${params.nodeKey}/apiDocs/${params.apiDocRelationId}`,
    method: "delete"
  });
}
// 获取测试计划的api测试报告
export function apiTestStatistics(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testPlans/${params.testPlanId}/apiTestStatistics`,
    method: "GET"
  });
}
// 获取测试计划的api测试报告中的api
export function getapiDocList(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/testPlans/${params.testPlanId}/apiDocList`,
    method: "get"
  });
}
// 获取项目的eolink配置信息
export function geteolinkConfig(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/eolinkConfig`,
    method: "get"
  });
}