import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 检查jenkins认证配置的连通性
export function testConn(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/jenkinsJobConfig/connectivity`,
        data: params.data,
        method: 'post'
    })
}
// 检查添加的Build Job链接的有效性
export function testJobConn(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/jenkinsJobConfig/buildJob/connectivity`,
        data: params.data,
        method: 'post'
    })
}
// 获取项目下的所有的build Job 列表
export function getJobList(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/jenkinsJobConfig/buildJobs`,
        method: 'get'
    })
}
// 保存新添加的Build Job链接
export function saveJob(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/jenkinsJobConfig/buildJobs`,
        method: 'post',
        data: params.data
    })
}
// 删除项目下指定的build job
export function deleteJob(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/jenkinsJobConfig/buildJobs/${params.configId}`,
        method: 'delete'
    })
}
// 获取节点关联的jenkins build history
export function getBuildHistory(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/jenkinsHistory`,
        method: 'get'
    })
}