import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 获取corax配置
export function getCoraxConfig(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/coraxConfig`,
        method: 'get'
    })
}
// 更新corax配置
export function modifyCoraxConfig(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/coraxConfig`,
        method: 'put',
        data: params,
    })
}
// 获取corax报告
export function getCoraxReportList(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/coraxReports`,
        method: 'get'
    })
}